<template>
  <div class="page">
    <div class="page-main">
       <div class="system-version">
          <span class="subhead">{{$t('about.systemVersion')}}</span>
          <div class="version">
            <span>{{$t('about.EDMVersion')}}: {{EDMV}}</span>
            <!-- <span class="link">
              <i class="iconfont icon-switch"></i>
              <a href="http://www.aledevice.com/" target="_blank">{{$t('about.releaseNotes')}}</a>
            </span> -->
          </div>
        </div>
        <div class="Supported">
          <span class="subhead">{{$t('about.Supported')}}</span>
          <table-pagination
            memory="about"
            ref="refTable"
            :tableData="tableData"
            :columnData="columnData"
            :optionMinWidth="120"
            paginationHide
          ></table-pagination>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { TablePagination } from '@/components'
import {
  getVersion,
  getAudioSupport
} from '@/api/aboutEDM'
export default {
  name: 'About',
  components: {
    TablePagination,
  },
  data(){
    return{
      EDMV: '',
      tableData:[],
    }
  },
  computed: {
    ...mapState('session', ['isLAN']),
    columnData() {
      let columnList = [
        {
          label: this.$t('about.model'),
          prop: 'model',
          minWidth: 200,
          sortable: 'custom',
        },
        {
          label: this.$t('about.minVersion'),
          prop: 'lowVersion',
          minWidth: 200,
          sortable: 'custom',
        },
      ]
      if (!this.isLAN) columnList.push({
        label: this.$t('about.maxVersion'),
        prop: 'highVersion',
        minWidth: 200,
        sortable: 'custom',
      })
      return columnList
    }
  },
  methods: {
    // 获取版本号
    getEDMVersion() {
      getVersion()
        .then(({ data }) => {
          this.EDMV = data.result.rows[0]
        })
    }
  },
  created() {
    //音频格式
    getAudioSupport()
      .then((res) => {
        this.tableData = res.data.result.rows
      })
    this.getEDMVersion()
  },
}
</script>

<style lang="scss" scoped>
.page-main {
  padding: 30px 0;
}
.system-version, .Supported{
  color: #666;
  .subhead {
    padding: 0 30px;
    font-size: 20px;
  }
}
.system-version {
  .version {
    margin-top: 20px;
    padding: 0 30px;
  }
  .link {
    margin-left: 100px;
    a {
      margin-left: 4px;
      text-decoration: none;
    }
    i, a {
      color: $theme-blue;
    }
    &:hover, &:active {
      i, a {
        color: rgba($color: $theme-blue, $alpha: .5)
      }
    }
  }
}
.Supported{
  margin-top: 30px;
  .dot,.subhead{
    display: inline-block;
    margin-bottom: 20px;
  }
}
</style>
